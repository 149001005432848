import { useContext, useState, useMemo } from "react";
import { AuthContext } from "../../context/AuthProvider";
import styled from "styled-components";
import Spinner from "../../components/Spinner";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import patientModel from "../../lib/firebase/patientModel";
import { patientKeys } from "../../lib/queryKeys";
import Button from "../../components/Button";
import mixpanel from 'mixpanel-browser';

const CRMDashboard = () => {
  const { userInfo } = useContext(AuthContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [isAddingPatient, setIsAddingPatient] = useState(false);
  const [editingPatient, setEditingPatient] = useState(null);
  const [viewingPredictions, setViewingPredictions] = useState(null);
  const [newPatient, setNewPatient] = useState({
    name: "",
    email: "",
    phone: "",
    status: "active",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "asc",
  });
  const queryClient = useQueryClient();

  // Sample prediction data - in a real app, this would come from your backend
  const mockPredictions = {
    "patient-id-1": [
      {
        id: "pred1",
        title: "Front View Analysis",
        type: "image",
        url: "https://via.placeholder.com/300x400?text=Front+View",
      },
      {
        id: "pred2",
        title: "Side View Analysis",
        type: "image",
        url: "https://via.placeholder.com/300x400?text=Side+View",
      },
    ],
    "patient-id-2": [
      {
        id: "pred3",
        title: "Movement Analysis",
        type: "video",
        url: "https://via.placeholder.com/640x480?text=Video",
      },
    ],
  };

  const { data: patients, isLoading } = useQuery({
    queryKey: patientKeys.user(userInfo?.id),
    queryFn: () => {
      if (!userInfo?.hasCrmAccess) return [];
      return patientModel.getMany(
        ["creatorId", "==", userInfo.id],
        ["sort", "createdAt", "desc"]
      );
    },
    placeholderData: [],
  });

  const handleSort = (key) => {
    mixpanel.track('CRM Sort Patients', {
      sort_key: key,
      new_direction: sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc'
    });
    setSortConfig({
      key,
      direction: sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc',
    });
  };

  // Phone number formatting function
  const formatPhone = (input) => {
    // Strip all non-digit characters
    const cleaned = input.replace(/\D/g, "");

    // Format according to length
    if (cleaned.length <= 3) {
      return cleaned;
    } else if (cleaned.length <= 6) {
      return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}`;
    } else {
      return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(
        6,
        10
      )}`;
    }
  };

  // Function to handle phone input changes
  const handlePhoneInput = (e, isNewPatient) => {
    const formatted = formatPhone(e.target.value);

    if (isNewPatient) {
      setNewPatient({ ...newPatient, phone: formatted });
    } else {
      setEditingPatient({ ...editingPatient, phone: formatted });
    }
  };

  const handleAddPatient = () => {
    mixpanel.track('CRM Add Patient Click', {
      current_patient_count: patients?.length || 0
    });
    setIsAddingPatient(true);
  };

  const handleUpdatePatient = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await patientModel.update(editingPatient.id, editingPatient);
      mixpanel.track('CRM Patient Updated', {
        patient_id: editingPatient.id,
        updated_fields: Object.keys(editingPatient)
      });
      setEditingPatient(null);
      queryClient.invalidateQueries(patientKeys.user(userInfo?.id));
    } catch (error) {
      console.error("Error updating patient:", error);
      mixpanel.track('CRM Update Error', {
        error_message: error.message,
        patient_id: editingPatient.id
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleViewPredictions = (patient, e) => {
    e.stopPropagation();
    mixpanel.track('CRM View Predictions', {
      patient_id: patient.id,
      patient_name: patient.name,
      prediction_count: getPatientPredictions(patient.id).length
    });
    setViewingPredictions(patient);
  };

  const handleSearch = (e) => {
    const term = e.target.value;
    mixpanel.track('CRM Search Patients', {
      search_term: term,
      result_count: patients?.filter(
        (patient) =>
          patient.name?.toLowerCase().includes(term.toLowerCase()) ||
          patient.email?.toLowerCase().includes(term.toLowerCase())
      ).length
    });
    setSearchTerm(term);
  };

  // Filter and sort patients
  const sortedAndFilteredPatients = useMemo(() => {
    let filtered =
      patients?.filter(
        (patient) =>
          patient.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          patient.email?.toLowerCase().includes(searchTerm.toLowerCase())
      ) || [];

    return filtered.sort((a, b) => {
      let aValue = a[sortConfig.key] || "";
      let bValue = b[sortConfig.key] || "";

      if (sortConfig.key === "lastVisit") {
        aValue = a.lastVisit ? new Date(a.lastVisit.toDate()) : new Date(0);
        bValue = b.lastVisit ? new Date(b.lastVisit.toDate()) : new Date(0);
      }

      if (aValue < bValue) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  }, [patients, searchTerm, sortConfig]);

  // Redirect if no CRM access
  if (!userInfo?.hasCrmAccess) {
    return (
      <UpgradeContainer>
        <UpgradeContent>
          <h1>Upgrade to Access CRM</h1>
          <p>The CRM feature is available with our premium subscription.</p>
          <Button
            onClick={() => (window.location.href = "/contact")}
            variant="primary"
          >
            Contact Sales
          </Button>
        </UpgradeContent>
      </UpgradeContainer>
    );
  }

  if (isLoading) return <Spinner />;

  const renderSortIcon = (key) => {
    if (sortConfig.key !== key) return "";
    return sortConfig.direction === "asc" ? "↑" : "↓";
  };

  // Get predictions for a patient
  const getPatientPredictions = (patientId) => {
    // In a real app, you'd fetch this from your backend
    return mockPredictions[patientId] || [];
  };

  return (
    <DashboardContainer>
      <FullWidthHeader>
        <HeaderTitle>
          <HeaderContent footerColor={userInfo?.footerColor}>
            {userInfo?.logoUrl && <Logo src={userInfo.logoUrl} alt="logo" />}
          </HeaderContent>
        </HeaderTitle>
        <SearchSection>
          <SearchBar
            type="text"
            placeholder="Search customers..."
            value={searchTerm}
            onChange={handleSearch}
          />
          <AddButton variant="primary" onClick={handleAddPatient}>
            Add Customer
          </AddButton>
        </SearchSection>
      </FullWidthHeader>
      <Content>
        <PatientList>
          <PatientHeader>
            <HeaderCell onClick={() => handleSort("name")}>
              Name {renderSortIcon("name")}
            </HeaderCell>
            <HeaderCell onClick={() => handleSort("email")}>
              Email {renderSortIcon("email")}
            </HeaderCell>
            <HeaderCell onClick={() => handleSort("phone")}>
              Phone {renderSortIcon("phone")}
            </HeaderCell>
            <HeaderCell onClick={() => handleSort("lastVisit")}>
              Last Visit {renderSortIcon("lastVisit")}
            </HeaderCell>
            <HeaderCell onClick={() => handleSort("status")}>
              Status {renderSortIcon("status")}
            </HeaderCell>
            <HeaderCell>Predictions</HeaderCell>
          </PatientHeader>
          {sortedAndFilteredPatients.length === 0 ? (
            <EmptyState>No patients found</EmptyState>
          ) : (
            sortedAndFilteredPatients.map((patient) => (
              <PatientRow
                key={patient.id}
                onClick={() => setEditingPatient(patient)}
              >
                <span>{patient.name || "N/A"}</span>
                <span>{patient.email || "N/A"}</span>
                <span>{patient.phone || "N/A"}</span>
                <span>
                  {patient.lastVisit
                    ? new Date(patient.lastVisit?.toDate()).toLocaleDateString()
                    : "Never"}
                </span>
                <StatusBadge status={patient.status || "inactive"}>
                  {patient.status || "Inactive"}
                </StatusBadge>
                <PredictionCell
                  onClick={(e) => handleViewPredictions(patient, e)}
                >
                  <ViewButton>View</ViewButton>
                </PredictionCell>
              </PatientRow>
            ))
          )}
        </PatientList>
      </Content>

      {/* Add Patient Modal */}
      {isAddingPatient && (
        <Modal>
          <ModalContent>
            <ModalHeader>
              <h2>Add New Customer</h2>
              <CloseButton onClick={() => setIsAddingPatient(false)}>
                ×
              </CloseButton>
            </ModalHeader>
            <form onSubmit={handleAddPatient}>
              <FormGroup>
                <label>Name:</label>
                <input
                  type="text"
                  value={newPatient.name}
                  onChange={(e) =>
                    setNewPatient({ ...newPatient, name: e.target.value })
                  }
                  placeholder="Enter customer name"
                  required
                />
              </FormGroup>
              <FormGroup>
                <label>Email:</label>
                <input
                  type="email"
                  value={newPatient.email}
                  onChange={(e) =>
                    setNewPatient({ ...newPatient, email: e.target.value })
                  }
                  placeholder="example@email.com"
                  required
                />
              </FormGroup>
              <FormGroup>
                <label>Phone:</label>
                <input
                  type="tel"
                  value={newPatient.phone}
                  onChange={(e) => handlePhoneInput(e, true)}
                  placeholder="(123) 456-7890"
                />
                <FormHint>Format: (XXX) XXX-XXXX</FormHint>
              </FormGroup>
              <FormGroup>
                <label>Status:</label>
                <select
                  value={newPatient.status}
                  onChange={(e) =>
                    setNewPatient({ ...newPatient, status: e.target.value })
                  }
                >
                  <option value="active">Active</option>
                  <option value="pending">Pending</option>
                  <option value="inactive">Inactive</option>
                </select>
              </FormGroup>
              <ModalActions>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => setIsAddingPatient(false)}
                >
                  Cancel
                </Button>
                <Button type="submit" variant="primary" disabled={isSubmitting}>
                  {isSubmitting ? "Adding..." : "Add Customer"}
                </Button>
              </ModalActions>
            </form>
          </ModalContent>
        </Modal>
      )}

      {/* Edit Patient Modal */}
      {editingPatient && (
        <Modal>
          <ModalContent>
            <ModalHeader>
              <h2>Edit Customer</h2>
              <CloseButton onClick={() => setEditingPatient(null)}>
                ×
              </CloseButton>
            </ModalHeader>
            <form onSubmit={handleUpdatePatient}>
              <FormGroup>
                <label>Name:</label>
                <input
                  type="text"
                  value={editingPatient.name}
                  onChange={(e) =>
                    setEditingPatient({
                      ...editingPatient,
                      name: e.target.value,
                    })
                  }
                  placeholder="Enter customer name"
                  required
                />
              </FormGroup>
              <FormGroup>
                <label>Email:</label>
                <input
                  type="email"
                  value={editingPatient.email}
                  onChange={(e) =>
                    setEditingPatient({
                      ...editingPatient,
                      email: e.target.value,
                    })
                  }
                  placeholder="example@email.com"
                  required
                />
              </FormGroup>
              <FormGroup>
                <label>Phone:</label>
                <input
                  type="tel"
                  value={editingPatient.phone}
                  onChange={(e) => handlePhoneInput(e, false)}
                  placeholder="(123) 456-7890"
                />
                <FormHint>Format: (XXX) XXX-XXXX</FormHint>
              </FormGroup>
              <FormGroup>
                <label>Status:</label>
                <select
                  value={editingPatient.status}
                  onChange={(e) =>
                    setEditingPatient({
                      ...editingPatient,
                      status: e.target.value,
                    })
                  }
                >
                  <option value="active">Active</option>
                  <option value="pending">Pending</option>
                  <option value="inactive">Inactive</option>
                </select>
              </FormGroup>
              <ModalActions>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => setEditingPatient(null)}
                >
                  Cancel
                </Button>
                <Button type="submit" variant="primary" disabled={isSubmitting}>
                  {isSubmitting ? "Saving..." : "Save Changes"}
                </Button>
              </ModalActions>
            </form>
          </ModalContent>
        </Modal>
      )}

      {/* Predictions Modal */}
      {viewingPredictions && (
        <Modal>
          <ModalContent>
            <ModalHeader>
              <h2>Customer Predictions</h2>
              <CloseButton onClick={() => setViewingPredictions(null)}>
                ×
              </CloseButton>
            </ModalHeader>
            <ModalTitle>Analysis for {viewingPredictions.name}</ModalTitle>
            <PredictionsContainer>
              {getPatientPredictions(viewingPredictions.id).length === 0 ? (
                <EmptyState>
                  No predictions available for this customer
                </EmptyState>
              ) : (
                getPatientPredictions(viewingPredictions.id).map(
                  (prediction) => (
                    <PredictionItem key={prediction.id}>
                      <PredictionTitle>{prediction.title}</PredictionTitle>
                      {prediction.type === "image" ? (
                        <PredictionImage
                          src={prediction.url}
                          alt={prediction.title}
                        />
                      ) : (
                        <PredictionVideo src={prediction.url} controls />
                      )}
                    </PredictionItem>
                  )
                )
              )}
            </PredictionsContainer>
            <ModalActions>
              <Button
                type="button"
                variant="primary"
                onClick={() => setViewingPredictions(null)}
              >
                Close
              </Button>
            </ModalActions>
          </ModalContent>
        </Modal>
      )}
    </DashboardContainer>
  );
};

const DashboardContainer = styled.div`
  padding: 0;
  width: 100%;
  max-width: 100%;
  margin: 0;
`;

const FullWidthHeader = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0;
  width: 100%;
  max-width: 100%;
  padding: 0;
`;

const HeaderTitle = styled.div`
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0;
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1%;
  width: 100%;
  background: ${props => props.footerColor || '#00001c'};
  margin: 0;

  h1 {
    font-size: 2rem;
    margin-bottom: 0;
    color: black;
  }
`;

const Logo = styled.img`
  width: 100%;
  height: 100px;
  object-fit: contain;
`;

const SearchSection = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 1rem 2rem;
  justify-content: center;
  background: #f5f5f5;
  border-bottom: 1px solid #eee;
  margin-top: 0;
  margin-bottom: 0;
`;

const SearchBar = styled.input`
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  max-width: 400px;
  font-size: 1rem;

  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

const Content = styled.div`
  background: white;
  padding: 2rem 0 0 0;
  width: 100%;
  margin: 0;
  max-width: 100%;
  overflow-x: auto;
`;

const PatientList = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 2rem 2rem 2rem;
`;

const PatientHeader = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr 1fr 1fr;
  column-gap: 1.5rem;
  padding: 1rem 2rem;
  background: #f8f9fa;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const PatientRow = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr 1fr 1fr;
  column-gap: 1.5rem;
  padding: 1rem 2rem;
  border-bottom: 1px solid #eee;
  align-items: center;
  cursor: pointer;
  text-align: left;

  &:hover {
    background: #f8f9fa;
  }
`;

const PredictionCell = styled.div`
  display: flex;
  justify-content: stretch;
  width: 100%;
`;

const ViewButton = styled.button`
  background-color: #f8f9fa;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  width: 100%;
  text-align: center;
  transition: all 0.2s ease;

  &:hover {
    background-color: darkgray;
    color: white;
    border-color: white;
  }
`;

const StatusBadge = styled.span`
  padding: 0.25rem 0.75rem;
  border-radius: 20px;
  text-transform: capitalize;
  font-size: 0.875rem;
  font-weight: 500;
  width: fit-content;

  ${({ status }) => {
    switch (status) {
      case "active":
        return "background: #e6f4ea; color: #1e7e34;";
      case "pending":
        return "background: #fff3cd; color: #856404;";
      default:
        return "background: #f8f9fa; color: #6c757d;";
    }
  }}
`;

const EmptyState = styled.div`
  text-align: center;
  padding: 2rem;
  color: #6c757d;
`;

const UpgradeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  padding: 2rem;
`;

const UpgradeContent = styled.div`
  text-align: center;
  max-width: 500px;

  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: var(--color-primary);
  }

  p {
    margin-bottom: 2rem;
    color: #666;
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  text-align: left;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  text-align: left;

  h2 {
    margin: auto;
    color: black;
    text-align: left;
    font-size: 1.5rem;
    font-weight: 600;
  }
`;

const ModalTitle = styled.h3`
  margin: 0 0 1.5rem 0;
  color: #333;
  font-size: 1.1rem;
  font-weight: 500;
  text-align: left;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  color: #666;

  &:hover {
    color: #333;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 1rem;
  text-align: left;

  label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
    text-align: left;
  }

  input,
  select {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    text-align: left;

    &:focus {
      outline: none;
      border-color: black;
    }

    &::placeholder {
      color: #aaa;
      font-style: italic;
    }
  }
`;

const FormHint = styled.div`
  font-size: 0.8rem;
  color: #666;
  margin-top: 0.25rem;
  font-style: italic;
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
`;

const PredictionsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1.5rem;
  margin-top: 1rem;
`;

const PredictionItem = styled.div`
  border: 1px solid #eee;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const PredictionTitle = styled.h4`
  margin: 0;
  padding: 0.75rem;
  background: #f8f9fa;
  font-size: 0.9rem;
  text-align: center;
`;

const PredictionImage = styled.img`
  width: 100%;
  height: auto;
  display: block;
`;

const PredictionVideo = styled.video`
  width: 100%;
  height: auto;
  display: block;
`;

const HeaderCell = styled.div`
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    color: rgb(135, 135, 135);
  }
`;

const AddButton = styled(Button)`
  max-width: 200px;
  white-space: nowrap;
`;

export default CRMDashboard;
