import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { device } from "../lib/breakpoints";
import { APP_NAME } from "../helpers/constants";
import mixpanel from "mixpanel-browser";

// This would typically come from your backend/database
const procedures = [
  {
    id: "hair-transplant",
    name: "Hair Transplant",
    category: "Hair Restoration",
    beforeImage: "/images/gallery/hair-transplant-before-2.jpg",
    afterImage: "/images/gallery/hair-transplant-after-2.png",
    description:
      "Natural-looking hair restoration results with improved density and coverage",
  },
  /*
  {
    id: "mammoplasty",
    name: "Mammoplasty",
    category: "Body Contouring",
    beforeImage: "",
    afterImage: "",
    description: "Breast augmentation enhancement with natural-looking results",
  },
  */
  {
    id: "botox",
    name: "Botox",
    category: "Facial Procedures",
    beforeImage: "/images/gallery/botox-before.jpg",
    afterImage: "/images/gallery/botox-after.png",
    description:
      "Targeted muscle relaxation to subtly smooth wrinkles and fine lines",
  },
  /*
  {
    id: "liposuction",
    name: "Liposuction",
    category: "Body Contouring",
    beforeImage: "",
    afterImage: "",
    description:
      "Targeted fat removal for improved body contouring and definition",
  },
  */
  {
    id: "rhinoplasty",
    name: "Rhinoplasty",
    category: "Facial Procedures",
    beforeImage: "/images/gallery/rhinoplasty-before.jpg",
    afterImage: "/images/gallery/rhinoplasty-after.png",
    description: "Nose reshaping results with improved facial harmony",
  },
  /*
  {
    id: "facelift",
    name: "Facelift",
    category: "Facial Procedures",
    beforeImage: "",
    afterImage: "",
    description: "Natural-looking facial rejuvenation with subtle improvements",
  },
  */
  {
    id: "lip-enhancement",
    name: "Lip Enhancement",
    category: "Facial Procedures",
    beforeImage: "/images/gallery/lip-enhancement-before.jpg",
    afterImage: "/images/gallery/lip-enhancement-after.png",
    description: "Subtle lip augmentation for improved facial balance",
  },
  /*
  {
    id: "blepharoplasty",
    name: "Blepharoplasty",
    category: "Facial Procedures",
    beforeImage: "",
    afterImage: "",
    description: "Eyelid surgery results with refreshed, youthful appearance",
  },
  */
  /*
  {
    id: "beard-transplant",
    name: "Beard Transplant",
    category: "Hair Restoration",
    beforeImage: "",
    afterImage: "",
    description:
      "Full, natural-looking beard restoration with improved density",
  },
  */
  /*
  {
    id: "eyebrow-transplant",
    name: "Eyebrow Transplant",
    category: "Hair Restoration",
    beforeImage: "",
    afterImage: "",
    description: "Natural-looking eyebrow restoration with improved shape",
  },
  */
  /*
  {
    id: "hair-removal",
    name: "Hair Removal",
    category: "Body Contouring",
    beforeImage: "",
    afterImage: "",
    description: "Permanent hair reduction with smooth, lasting results",
  },
  */
  {
    id: "tattoo-removal",
    name: "Tattoo Removal",
    category: "Body Contouring",
    beforeImage: "/images/gallery/tattoo-removal-before.jpg",
    afterImage: "/images/gallery/tattoo-removal-after.png",
    description: "Permanent tattoo removal with smooth, lasting results",
  },
];

const categories = [...new Set(procedures.map((proc) => proc.category))];

const getCategoryImage = (category) => {
  const procedure = procedures.find((proc) => proc.category === category);
  return procedure ? procedure.beforeImage : procedures[0].beforeImage;
};

export default function Gallery() {
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [hoveredProcedure, setHoveredProcedure] = useState(null);
  const [loadedImages, setLoadedImages] = useState({});
  const [imageErrors, setImageErrors] = useState({});

  // Track page view
  useEffect(() => {
    mixpanel.track("Gallery Page View", {
      total_procedures: procedures.length,
      categories: categories,
    });
  }, []);

  const filteredProcedures =
    selectedCategory === "all"
      ? procedures
      : procedures.filter((proc) => proc.category === selectedCategory);

  const handleImageLoad = (procedureId, type) => {
    setLoadedImages((prev) => ({
      ...prev,
      [`${procedureId}-${type}`]: true,
    }));
  };

  const handleImageLoadError = (procedure, type) => {
    console.error(`Failed to load ${type} image for ${procedure.name}`);
    setImageErrors((prev) => ({
      ...prev,
      [`${procedure.id}-${type}`]: true,
    }));
  };

  // Track category selection
  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    mixpanel.track("Gallery Category Selected", {
      category: category,
      total_procedures_in_category:
        category === "all"
          ? procedures.length
          : procedures.filter((proc) => proc.category === category).length,
    });
  };

  return (
    <Container>
      <ContentWrapper>
        <Header>
          <HeaderContent>
            <Title>Gallery</Title>
            <Subtitle>
              Explore some of our {APP_NAME} prediction visualizations
            </Subtitle>
            <CategoryFilter>
              <FilterButton
                active={selectedCategory === "all"}
                onClick={() => handleCategorySelect("all")}
              >
                All Procedures
              </FilterButton>
              {categories.map((category) => (
                <FilterButton
                  key={category}
                  active={selectedCategory === category}
                  onClick={() => handleCategorySelect(category)}
                >
                  {category}
                </FilterButton>
              ))}
            </CategoryFilter>
          </HeaderContent>
        </Header>

        <GalleryGrid>
          {filteredProcedures.map((procedure) => (
            <GalleryItem key={procedure.id}>
              <ImageContainer>
                <ImageWrapper>
                  <ImageSection>
                    <ImageLabel>Initial</ImageLabel>
                    <BeforeImage
                      src={procedure.beforeImage}
                      alt={`${procedure.name} - Before`}
                      onLoad={() => handleImageLoad(procedure.id, "before")}
                      onError={() => handleImageLoadError(procedure, "before")}
                    />
                    {!loadedImages[`${procedure.id}-before`] &&
                      !imageErrors[`${procedure.id}-before`] && (
                        <LoadingPlaceholder>
                          <LoadingSpinner />
                        </LoadingPlaceholder>
                      )}
                    {imageErrors[`${procedure.id}-before`] && (
                      <ErrorPlaceholder>Failed to load image</ErrorPlaceholder>
                    )}
                  </ImageSection>
                  <ImageSection>
                    <ImageLabel>Prediction</ImageLabel>
                    <AfterImage
                      src={procedure.afterImage}
                      alt={`${procedure.name} - After`}
                      onLoad={() => handleImageLoad(procedure.id, "after")}
                      onError={() => handleImageLoadError(procedure, "after")}
                    />
                    {!loadedImages[`${procedure.id}-after`] &&
                      !imageErrors[`${procedure.id}-after`] && (
                        <LoadingPlaceholder>
                          <LoadingSpinner />
                        </LoadingPlaceholder>
                      )}
                    {imageErrors[`${procedure.id}-after`] && (
                      <ErrorPlaceholder>Failed to load image</ErrorPlaceholder>
                    )}
                  </ImageSection>
                </ImageWrapper>
              </ImageContainer>
              <ProcedureInfo>
                <ProcedureName>{procedure.name}</ProcedureName>
                <ProcedureDescription>
                  {procedure.description}
                </ProcedureDescription>
              </ProcedureInfo>
            </GalleryItem>
          ))}
        </GalleryGrid>
      </ContentWrapper>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #f8f9fa;
  padding: 0 0 4rem;

  @media ${device.tablet} {
    padding: 0 0 3rem;
  }

  @media ${device.mobile} {
    padding: 0 0 2rem;
  }
`;

const ContentWrapper = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
`;

const Header = styled.div`
  background: linear-gradient(135deg, #1a237e 0%, #0d47a1 100%);
  padding: 5rem 0;
  margin-bottom: 3rem;
  position: relative;
  overflow: hidden;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(
        circle at 20% 20%,
        rgba(255, 255, 255, 0.1) 0%,
        transparent 50%
      ),
      radial-gradient(
        circle at 80% 80%,
        rgba(255, 255, 255, 0.1) 0%,
        transparent 50%
      );
    pointer-events: none;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.1) 25%,
        transparent 25%
      ),
      linear-gradient(-45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%),
      linear-gradient(45deg, transparent 75%, rgba(255, 255, 255, 0.1) 75%),
      linear-gradient(-45deg, transparent 75%, rgba(255, 255, 255, 0.1) 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
    opacity: 0.1;
    pointer-events: none;
  }

  & > * {
    position: relative;
    z-index: 1;
  }

  @media ${device.tablet} {
    padding: 4rem 0;
  }

  @media ${device.mobile} {
    padding: 3rem 0;
  }
`;

const HeaderContent = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 1;
  padding: 0 2rem;

  @media ${device.mobile} {
    padding: 0 1rem;
  }
`;

const Title = styled.h1`
  font-size: 4rem;
  font-weight: 700;
  color: white;
  margin: 0 0 1.5rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  letter-spacing: -0.02em;
  position: relative;
  display: inline-block;
  line-height: 1.2;

  &::after {
    content: "";
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 4px;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.5),
      transparent
    );
    border-radius: 2px;
  }

  @media ${device.tablet} {
    font-size: 3.5rem;
    margin: 0 0 1.2rem;
  }

  @media ${device.mobile} {
    font-size: 3rem;
    margin: 0 0 1rem;
  }
`;

const Subtitle = styled.p`
  font-size: 1.4rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.98);
  margin: 0 0 2rem;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  font-weight: 400;

  @media ${device.tablet} {
    font-size: 1.3rem;
    margin: 0 0 1.8rem;
  }

  @media ${device.mobile} {
    font-size: 1rem;
    margin: 0 0 1.5rem;
  }
`;

const CategoryFilter = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.8rem;
  flex-wrap: wrap;
  padding: 0 1rem;
  margin-top: 1rem;

  @media ${device.mobile} {
    gap: 0.6rem;
    padding: 0 0.5rem;
  }
`;

const FilterButton = styled.button`
  padding: 0.8rem 1.8rem;
  border: 2px solid
    ${(props) => (props.active ? "white" : "rgba(255, 255, 255, 0.3)")};
  background: ${(props) => (props.active ? "white" : "transparent")};
  color: ${(props) => (props.active ? "#1a237e" : "white")};
  border-radius: 25px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1rem;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  box-shadow: ${(props) =>
    props.active ? "0 4px 12px rgba(0, 0, 0, 0.15)" : "none"};

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${(props) =>
      props.$backgroundImage ? `url(${props.$backgroundImage})` : "none"};
    background-size: cover;
    background-position: center;
    opacity: ${(props) => (props.active ? 0.1 : 0.05)};
    z-index: -1;
  }

  &:hover {
    background: ${(props) =>
      props.active ? "white" : "rgba(255, 255, 255, 0.1)"};
    border-color: white;
    color: ${(props) => (props.active ? "#1a237e" : "white")};
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);

    &::before {
      opacity: ${(props) => (props.active ? 0.1 : 0.1)};
    }
  }

  @media ${device.mobile} {
    padding: 0.6rem 1.4rem;
    font-size: 0.95rem;
  }
`;

const GalleryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
  gap: 2rem;
  margin-top: 2rem;

  @media ${device.tablet} {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  @media ${device.mobile} {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
`;

const GalleryItem = styled.div`
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.07);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: #f0f0f0;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  gap: 2px;
`;

const ImageSection = styled.div`
  position: relative;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ImageLabel = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 500;
  z-index: 1;

  @media ${device.mobile} {
    bottom: 0;
    left: 0;
    border-radius: 0px 4px 0px 0px;
    top: unset;
  }
`;

const BeforeImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #f0f0f0;
`;

const AfterImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #f0f0f0;
`;

const LoadingPlaceholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.div`
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid var(--color-brand-primary);
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const ProcedureInfo = styled.div`
  padding: 1.5rem;
`;

const ProcedureName = styled.h3`
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--color-brand-primary);
  margin: 0 0 0.5rem;
`;

const ProcedureDescription = styled.p`
  font-size: 0.95rem;
  color: #666;
  margin: 0;
  line-height: 1.5;
`;

const ErrorPlaceholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  font-size: 0.9rem;
`;
