import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { FaqSection } from "../components/general";
import { useEffect } from "react";
import { device } from "../lib/breakpoints";
import { MainHeader, Spacer } from "../styles/CommonElements";
import { APP_NAME } from "../helpers/constants";

const bannerImage = "/images/faq/faq-banner-000.svg";

export default function FAQ() {
  const location = useLocation();

  useEffect(() => {
    window.scroll(0, 0);
  }, [location.pathname]);

  return (
    <Container>
      <BannerContainer>
        <BannerImage src={bannerImage} alt="" />
        <HeaderContent>
          <CenteredHeader>Frequently Asked Questions</CenteredHeader>
          <Subtitle>Find answers to common questions about {APP_NAME}'s visualization technology and services</Subtitle>
        </HeaderContent>
      </BannerContainer>
      <FaqContainer>
        <Spacer $marginTop="2.5rem" />
        <FaqSection />
      </FaqContainer>
      <Spacer $marginTop="2rem" />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;

const BannerContainer = styled.div`
  position: relative;
  width: 100%;
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    z-index: 1;
  }
`;

const BannerImage = styled.img`
  width: 100%;
  height: 400px;
  object-fit: cover;
  object-position: center;
  position: relative;
  z-index: 0;

  @media ${device.mobile} {
    height: 200px;
  }
`;

const HeaderContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  width: 100%;
  padding: 0 20px;
  z-index: 2;
`;

const CenteredHeader = styled(MainHeader)`
  color: white;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  font-weight: 600;
  margin-bottom: 1rem;
  
  @media ${device.tablet} {
    font-size: 2.5rem;
  }
  
  @media ${device.desktop} {
    font-size: 2.5rem;
    letter-spacing: 0.5px;
  }
`;

const Subtitle = styled.p`
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.2rem;
  line-height: 1.6;
  max-width: 600px;
  margin: 0 auto;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);

  @media ${device.tablet} {
    font-size: 1.1rem;
  }

  @media ${device.mobile} {
    font-size: 1rem;
    padding: 0 1rem;
  }
`;

const FaqContainer = styled.div`
  @media ${device.tablet} {
    width: 600px;
    margin: auto;
  }

  @media ${device.desktop} {
    width: 800px;
    margin: auto;
  }
`;
