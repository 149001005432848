import React, { useEffect } from "react";
import { Row, Col } from "antd";
import styled from "styled-components";
import { useState } from "react";
import { EditGenAiOptions } from "./gen-ai";
import { ReactSVG } from "react-svg";

const moreOptionsIcon = "/icons/gear.svg";
const createVariationsIcon = "/icons/variations.svg";

export default function EditGenAi({
  quest,
  isGenerating,
  selectedImage,
  createGeneration,
  createVariations,
  negativePrompt,
  setNegativePrompt,
  imageUrl,
  setImageUrl,
  typedImageUrl,
  setTypedImageUrl,
  limitReached,
  hasUploadedImage,
  setHasUploadedImage,
  hasTypedInUrl,
  setHasTypedInUrl,
}) {
  const [isMoreOptionsOpen, setIsMoreOptionsOpen] = useState(false);
  const [isCreateVariationsDisabled, setIsCreateVariationsDisabled] = useState(
    false
  );

  useEffect(() => {
    if (isGenerating || !selectedImage) {
      setIsCreateVariationsDisabled(true);
    } else {
      setIsCreateVariationsDisabled(false);
    }
  }, [isGenerating, selectedImage, typedImageUrl, imageUrl]);

  useEffect(() => {
    if (isMoreOptionsOpen) window.scroll(0, 0);
  }, [isMoreOptionsOpen]);

  return (
    <>
      <Container>
        <Row gutter={12}>
          <Col span={8}>
            <ButtonCard
              disabled={isGenerating || limitReached}
              onClick={createGeneration}
            ></ButtonCard>
          </Col>
          <Col span={8}>
            <ButtonCard
              disabled={isGenerating || limitReached}
              onClick={() => {
                setIsMoreOptionsOpen(true);
              }}
            >
              {isGenerating || limitReached ? (
                <DisabledOpacityDiv>
                  <ReactSVG
                    src={moreOptionsIcon}
                    style={{ color: "#9887DB" }}
                  />
                  <ButtonText>More Options</ButtonText>
                </DisabledOpacityDiv>
              ) : (
                <div>
                  <ReactSVG
                    src={moreOptionsIcon}
                    style={{ color: "#9887DB" }}
                  />
                  <ButtonText>More Options</ButtonText>
                </div>
              )}
            </ButtonCard>
          </Col>
          <Col span={8}>
            <ButtonCard
              disabled={isCreateVariationsDisabled || limitReached}
              onClick={createVariations}
            >
              {isCreateVariationsDisabled || limitReached ? (
                <DisabledOpacityDiv>
                  <ButtonIconTopMargin src={createVariationsIcon} />
                  <ButtonText>Create Variations</ButtonText>
                </DisabledOpacityDiv>
              ) : (
                <div>
                  <ButtonIconTopMargin src={createVariationsIcon} />
                  <ButtonText>Create Variations</ButtonText>
                </div>
              )}
            </ButtonCard>
          </Col>
        </Row>
      </Container>
      <EditGenAiOptions
        quest={quest}
        isOpen={isMoreOptionsOpen}
        setOpen={setIsMoreOptionsOpen}
        setNegativePrompt={setNegativePrompt}
        negativePrompt={negativePrompt}
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
        typedImageUrl={typedImageUrl}
        setTypedImageUrl={setTypedImageUrl}
        createGeneration={createGeneration}
        hasUploadedImage={hasUploadedImage}
        setHasUploadedImage={setHasUploadedImage}
        hasTypedInUrl={hasTypedInUrl}
        setHasTypedInUrl={setHasTypedInUrl}
      />
    </>
  );
}

const Container = styled.div`
  border-radius: 8px !important;
  display: block;
  width: 100%;
  height: 100%;
  color: white;
`;

const DisabledOpacityDiv = styled.div`
  opacity: 0.2;
`;

const ButtonCard = styled.button`
  background: var(--color-gray-300);
  width: 100%;
  height: 100%;
  min-height: 100px;
  padding: 6px;
  color: var(--successColor);
  border: 0;
  border-radius: 8px;
  display: block;
`;

const ButtonIcon = styled.img`
  height: 28px;
  margin-bottom: 6px;
  text-align: center;
`;

const ButtonIconTopMargin = styled.img`
  height: 28px;
  margin-top: -2px;
  margin-bottom: 2px;
  text-align: center;
`;

const ButtonText = styled.h4`
  height: 18px;
  color: var(--color-cream);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16.8px;
`;
