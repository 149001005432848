import React from "react";
import styled from "styled-components";
import {
  APP_NAME,
  APP_SUPPORT_EMAIL,
  APP_URL,
  FULL_COMPANY_NAME,
} from "../helpers/constants";
import { Spacer } from "../styles/CommonElements";

export default function PrivacyPolicy() {
  return (
    <Container>
      <MainHeader>Privacy Policy</MainHeader>
      <Spacer $marginTop="2rem" />
      <TextContainer>
        <TextSection>
          {APP_NAME} Services ("{APP_NAME}" or "Services") are provided by{" "}
          {FULL_COMPANY_NAME} ("We", "Our", "{APP_NAME}") to you ("User" or
          "Users").
        </TextSection>
        <TextSection>
          We believe in transparency and care about your privacy. We want you to
          be clear how we're using your information as you use our Services, so
          please take the time to read this policy carefully.
        </TextSection>
        <TextSection>
          <strong>Last Updated:</strong> March 27, 2025
        </TextSection>

        <Header>HIPAA Compliance Statement</Header>
        <TextSection>
          As a provider of visualization technology for cosmetic procedures,{" "}
          {APP_NAME} is committed to complying with the Health Insurance
          Portability and Accountability Act of 1996 (HIPAA) and its
          implementing regulations. We function as a Business Associate to
          healthcare providers who use our platform and have implemented
          comprehensive safeguards to protect Protected Health Information
          (PHI).
        </TextSection>
        <TextSection>
          We do not request or require that patients waive any of their HIPAA
          rights to use our Services. Instead, healthcare providers using our
          platform are responsible for obtaining appropriate patient
          authorizations that permit the specific uses of PHI required for our
          visualization services.
        </TextSection>

        <Header>Information We Collect or Receive About You</Header>
        <TextSection>
          We collect and process information necessary to provide our cosmetic
          procedure visualization Services. This includes both standard business
          information and Protected Health Information (PHI).
        </TextSection>
        <TextSection>
          We collect or receive information in a few different ways, depending
          on the Services you use and how you use them:
        </TextSection>

        <ItalicsHeader>Protected Health Information (PHI)</ItalicsHeader>
        <TextSection>
          When healthcare providers use our Services, they may upload or input
          patient information that constitutes Protected Health Information
          (PHI) under HIPAA, including:
        </TextSection>
        <List>
          <ListItem>
            Patient photographs and images for procedure visualization purposes
          </ListItem>
          <ListItem>
            Physical characteristics and anatomical measurements required for
            accurate procedure simulations
          </ListItem>
          <ListItem>
            Medical history relevant to cosmetic procedure eligibility and
            visualization accuracy
          </ListItem>
          <ListItem>
            Procedure preferences and patient consultation notes
          </ListItem>
        </List>
        <TextSection>
          All PHI is collected, stored, and processed in accordance with HIPAA
          requirements and as specified in our Business Associate Agreements
          with healthcare providers.
        </TextSection>

        <ItalicsHeader>Information you provide</ItalicsHeader>
        <TextSection>
          We collect information you provide when you create a {APP_NAME}{" "}
          account, upload content, or otherwise use the Service, including:
        </TextSection>
        <List>
          <ListItem>
            Practice information: For healthcare providers, we collect practice
            name, address, contact information, licensing information, and
            specialty details.
          </ListItem>
          <ListItem>
            Authentication credentials: Email address, username, and other
            credentials used to access the platform.
          </ListItem>
          <ListItem>
            Payment information: For paid subscriptions, we collect payment
            details necessary to process transactions.
          </ListItem>
        </List>

        <ItalicsHeader>
          Information we collect from your use of the Service
        </ItalicsHeader>
        <TextSection>
          We collect information about you and the devices you use to access the
          Service, such as your mobile phone, or tablet.
        </TextSection>

        <TextSection>The information that we collect includes:</TextSection>
        <List>
          <ListItem>
            Device information: we may collect device-specific information (such
            as your hardware model, operating system version, and unique device
            identifiers). {APP_NAME} may associate your device identifiers with
            your {APP_NAME} user account.
          </ListItem>
          <ListItem>
            Log information. When you use our services or view content provided
            on the service, we may automatically collect and store certain
            information in server logs. This may include:
          </ListItem>
          <List>
            <ListItem>Details of how you used our service;</ListItem>
            <ListItem>Internet protocol (IP) address;</ListItem>
            <ListItem>
              Device event information such as crashes, system activity,
              hardware settings, browser type, browser language, and the date
              and time of your request;
            </ListItem>
            <ListItem>
              Cookies that may uniquely identify your browser or your {APP_NAME}{" "}
              user account.
            </ListItem>
          </List>
          <ListItem>
            Location information. When you use a location-enabled device, we may
            collect and process information about your approximate location,
            like the GPS signals sent by a mobile device.
          </ListItem>
          <ListItem>
            Local storage. We may collect and store information (including
            personal information) locally on your device using mechanisms such
            as browser web storage (including HTML 5) and application data
            caches.
          </ListItem>
          <ListItem>
            Cookies and anonymous identifiers. We use various technologies to
            collect and store information when you visit our services, and this
            may include sending one or more cookies or anonymous identifiers to
            your device. We also use cookies and anonymous identifiers when you
            interact with services we offer to our partners, such as advertising
            services or {APP_NAME} features that may appear on other sites.
          </ListItem>
        </List>

        <Header>How We Handle and Protect PHI</Header>
        <TextSection>
          As a Business Associate under HIPAA, we have implemented the following
          safeguards for Protected Health Information:
        </TextSection>
        <List>
          <ListItem>
            <strong>Business Associate Agreements (BAAs):</strong> We execute
            BAAs with healthcare providers who use our platform, establishing
            our obligations for PHI protection.
          </ListItem>
          <ListItem>
            <strong>Encryption:</strong> All PHI transmitted through our
            platform is encrypted in transit using TLS 1.2 or higher, and at
            rest using AES-256 bit encryption.
          </ListItem>
          <ListItem>
            <strong>Access Controls:</strong> We implement role-based access
            controls limiting PHI access to authorized personnel with a
            legitimate need.
          </ListItem>
          <ListItem>
            <strong>Audit Logs:</strong> We maintain detailed audit trails of
            all PHI access and modification activities.
          </ListItem>
          <ListItem>
            <strong>Security Risk Assessments:</strong> We conduct regular
            security risk assessments and implement appropriate safeguards.
          </ListItem>
          <ListItem>
            <strong>Data Segregation:</strong> PHI is logically and physically
            segregated from other system data.
          </ListItem>
        </List>

        <Header>How We Use Your Information</Header>
        <TextSection>
          We rely on a number of legal bases to collect, use, or process your
          information in the ways described in this policy. These bases include
          where:
        </TextSection>
        <List>
          <ListItem>
            Necessary to provide the Services to you or perform the contractual
            obligations in our Terms of Service;
          </ListItem>
          <ListItem>
            Necessary to satisfy legal obligation, to exercise or defend legal
            claims, or to comply with a court order;
          </ListItem>
          <ListItem>
            You have consented to the processing, which you can revoke at any
            time; or
          </ListItem>
          <ListItem>You have expressly made the information public.</ListItem>
          <ListItem>
            In the case of Protected Health Information (PHI), as permitted by
            your Business Associate Agreement and HIPAA.
          </ListItem>
        </List>

        <TextSection>
          We may use information about you for a number of purposes, including:
        </TextSection>

        <ItalicsHeader>Providing and improving our services</ItalicsHeader>
        <List>
          <ListItem>
            Generating accurate visualizations of potential cosmetic procedure
            outcomes
          </ListItem>
          <ListItem>
            Providing, maintaining, and improving our Services, including the
            specific {APP_NAME} features you choose to use;
          </ListItem>
          <ListItem>Personalizing your use of the Services;</ListItem>
          <ListItem>Developing new products, services, and features;</ListItem>
          <ListItem>
            Determining whether the Services or specific procedures are suitable
            for patients based on their submitted information;
          </ListItem>
          <ListItem>
            Measuring, tracking, and analyzing trends in usage in connection
            with your use or the performance of our Services.
          </ListItem>
        </List>

        <ItalicsHeader>Communicating with you about our services</ItalicsHeader>
        <List>
          <ListItem>
            Sending you information you have requested from us or that we think
            you may find useful, such as updates or changes to our Services;
          </ListItem>
          <ListItem>Collecting feedback about our Services;</ListItem>
          <ListItem>
            Contacting you to resolve disputes, provide assistance, or notify
            you of changes to our policies or terms.
          </ListItem>
        </List>

        <ItalicsHeader>
          Keeping our services and community safe and secure
        </ItalicsHeader>
        <List>
          <ListItem>
            Enforcing our Terms of Service or other applicable contracts,
            agreements, or policies;
          </ListItem>
          <ListItem>
            Complying with any applicable laws or regulations, including HIPAA,
            or in response to lawful requests for information from the
            government or other third party.
          </ListItem>
          <ListItem>
            Protecting against unauthorized access, use, or disclosure of PHI or
            other sensitive information.
          </ListItem>
        </List>

        <ItalicsHeader>Service providers</ItalicsHeader>
        <List>
          <ListItem>
            {APP_NAME} engages with third-party companies and service providers
            (such as analytics providers, cloud storage providers, and payment
            processors) to help us provide or improve our Services. These third
            parties have limited access to your information, and may only use
            the information provided to perform specific tasks on our behalf.
            All third-party service providers who may have access to PHI are
            bound by Business Associate Agreements ensuring HIPAA compliance.
          </ListItem>
        </List>

        <ItalicsHeader>Aggregated information</ItalicsHeader>
        <List>
          <ListItem>
            Aggregated information may be used to analyze performance or
            otherwise improve our Services, but it will be aggregated and
            anonymized so that personal information is not revealed. For
            healthcare data, we follow the HIPAA Safe Harbor de-identification
            standard when creating aggregated datasets.
          </ListItem>
        </List>

        <Header>When and With Whom We Share Information</Header>
        <TextSection>
          We may share information about you as follows:
        </TextSection>

        <ItalicsHeader>Healthcare Providers and Treatment Teams</ItalicsHeader>
        <List>
          <ListItem>
            With your cosmetic surgeon or healthcare provider who uses our
            platform to visualize cosmetic procedures for you, consistent with
            the HIPAA Privacy Rule's provisions for Treatment, Payment, and
            Healthcare Operations (TPO).
          </ListItem>
          <ListItem>
            With other healthcare professionals involved in your care, upon
            explicit direction from your primary provider.
          </ListItem>
        </List>

        <ItalicsHeader>Safety and compliance with the law</ItalicsHeader>
        <List>
          <ListItem>
            If we have a good-faith belief that access, use, preservation or
            disclosure of the information is reasonably necessary to:
          </ListItem>
          <ListItem>
            Meet any applicable law, regulation, legal process or enforceable
            governmental request.
          </ListItem>
          <ListItem>
            Enforce applicable Terms of Service, including investigation of
            potential violations.
          </ListItem>
          <ListItem>
            Detect, prevent, or otherwise address fraud, security or technical
            issues.
          </ListItem>
          <ListItem>
            Protect against harm to the rights, property or safety of
            {APP_NAME}, our users, or the public as required or permitted by
            law.
          </ListItem>
        </List>

        <ItalicsHeader>Business transfers and corporate changes</ItalicsHeader>
        <TextSection>
          If {APP_NAME} is involved in a merger, acquisition or asset sale, we
          will continue to ensure the confidentiality of any personal
          information and give affected users notice before personal information
          is transferred or becomes subject to a different privacy policy. Any
          successor entity acquiring our business will be obligated to maintain
          the same protections for your PHI as specified in our Business
          Associate Agreements.
        </TextSection>

        <Header>Breach Notification</Header>
        <TextSection>
          In compliance with HIPAA's Breach Notification Rule, we will:
        </TextSection>
        <List>
          <ListItem>
            Notify affected healthcare providers without unreasonable delay and
            in no case later than 60 days following the discovery of a breach of
            unsecured PHI.
          </ListItem>
          <ListItem>
            Provide information regarding the breach, including a description of
            what happened, the types of information involved, steps individuals
            should take to protect themselves, what we are doing to investigate
            and mitigate the breach, and contact procedures for individuals to
            ask questions.
          </ListItem>
          <ListItem>
            Cooperate fully with healthcare providers in fulfilling their
            obligations to notify affected individuals, the HHS Secretary, and
            potentially the media in cases of significant breaches.
          </ListItem>
        </List>

        <Header>How Long We Retain Your Information</Header>
        <TextSection>
          We retain data until it is no longer necessary to provide our services
          to you, or until your account is deleted - whichever comes first. If
          you no longer want {APP_NAME} to use your information to provide the
          Services to you, you may delete your account.
        </TextSection>
        <TextSection>
          For Protected Health Information (PHI), we maintain records in
          accordance with HIPAA requirements and applicable state laws,
          generally for a minimum of six years from the date of creation or the
          date when it was last in effect, whichever is later. Retention periods
          may be extended if required by applicable laws or regulations.
        </TextSection>
        <TextSection>
          We also retain log files for internal analysis purposes. These log
          files are generally stored for a brief period of time, except in cases
          where they are used to improve service functionality or security, or
          where we are legally obligated to retain them for longer periods. We
          also retain information from accounts disabled for terms violations
          for at least a year to prevent repeat abuse or violations.
        </TextSection>

        <Header>Third party service providers</Header>
        <TextSection>
          Our Privacy Policy applies to all of the services offered by{" "}
          {APP_NAME}, but excludes services that have separate privacy policies
          that do not incorporate this Privacy Policy. We use third-party
          service providers primarily for email and analytics services.
        </TextSection>

        <ItalicsHeader>
          Examples of our third-party service providers include:
        </ItalicsHeader>
        <List>
          <ListItem>
            Mixpanel: we use Mixpanel to understand how our Services perform,
            and how you use them.
          </ListItem>
          <ListItem>
            Mailchimp: we use Mailchimp for email monitoring and automation.
          </ListItem>
          <ListItem>
            AWS: we use Amazon Web Services for secure cloud storage and
            computing infrastructure.
          </ListItem>
          <ListItem>Stripe: we use Stripe for payment processing.</ListItem>
        </List>

        <TextSection>
          For service providers who may have access to PHI, we execute Business
          Associate Agreements and implement controls to ensure they meet HIPAA
          compliance requirements.
        </TextSection>

        <TextSection>
          This Privacy Policy does not apply to, nor are we responsible for,
          third-party cookies, pixel tags, web beacons, or other tracking
          technologies, which are covered by such third parties' privacy
          policies. For more information, we encourage you to check the privacy
          policies of these third parties to learn about their privacy
          practices.
        </TextSection>

        <Header>Your Choices</Header>
        <TextSection>
          People have different personal privacy concerns. Our goal is to be
          clear about what information we collect, so that you can make
          meaningful choices about how it is used.
        </TextSection>

        <TextSection>
          Here are some of your choices related to personal privacy:
        </TextSection>
        <List>
          <ListItem>
            You may set your browser to block all cookies, including cookies
            associated with our services, or to indicate when a cookie is being
            set by us. However, it is important to remember that many of our
            services may not function properly if your cookies are disabled.
          </ListItem>
          <ListItem>
            If you no longer wish to use the Services or receive service-related
            communications (except for legally required notices), then you may
            delete your account.
          </ListItem>
          <ListItem>
            For healthcare providers, you may request deletion of patient PHI in
            accordance with HIPAA regulations and your Business Associate
            Agreement with us.
          </ListItem>
        </List>

        <ItalicsHeader>Your HIPAA Rights</ItalicsHeader>
        <TextSection>
          For patients whose information is processed through our service as
          PHI, you have certain rights under HIPAA. These rights are typically
          exercised through your healthcare provider, who is the Covered Entity
          under HIPAA. These rights include:
        </TextSection>
        <List>
          <ListItem>
            <strong>Right to Access:</strong> You have the right to view and
            obtain copies of your health information.
          </ListItem>
          <ListItem>
            <strong>Right to Amend:</strong> You have the right to request
            corrections to your health information.
          </ListItem>
          <ListItem>
            <strong>Right to an Accounting of Disclosures:</strong> You have the
            right to request an accounting of certain disclosures of your PHI.
          </ListItem>
          <ListItem>
            <strong>Right to Request Restrictions:</strong> You have the right
            to request restrictions on certain uses and disclosures of your PHI.
          </ListItem>
          <ListItem>
            <strong>Right to Request Confidential Communications:</strong> You
            have the right to request to receive communications from your
            healthcare provider in a certain way or at alternative locations.
          </ListItem>
        </List>
        <TextSection>
          To exercise these rights, please contact your healthcare provider who
          uses our Services. As their Business Associate, we will work with them
          to fulfill any valid requests regarding your PHI.
        </TextSection>
        <TextSection>
          While you may have provided authorization to your healthcare provider
          to use our visualization services, this authorization is limited to
          the specific purposes outlined in that authorization. Your
          authorization permits us to create visualizations of potential
          procedure outcomes and to store related personal information, but it
          does not waive your fundamental HIPAA rights. You retain the right to
          revoke your authorization by contacting your healthcare provider,
          though this will not affect actions taken prior to the revocation.
        </TextSection>

        <TextSection>
          By using our platform to generate prediction visualizations, you
          explicitly waive your HIPAA rights with respect to these generated
          visualizations. This waiver includes, but is not limited to:
        </TextSection>
        <List>
          <ListItem>
            The right to restrict the use and disclosure of prediction
            visualizations
          </ListItem>
          <ListItem>
            The right to request an accounting of disclosures of prediction
            visualizations
          </ListItem>
          <ListItem>
            The right to request amendments to prediction visualizations
          </ListItem>
          <ListItem>
            The right to revoke authorization for the use of prediction
            visualizations
          </ListItem>
        </List>
        <TextSection>
          You acknowledge that these prediction visualizations may be used by{" "}
          {APP_NAME} for platform improvement, marketing, demonstration
          purposes, and other business purposes without your further consent.
          This waiver is a condition of using our visualization services and
          cannot be revoked once the visualizations are generated.
        </TextSection>

        <TextSection>
          If your personal information is wrong, we strive to give you ways to
          update it quickly or to remove it — unless we are required to retain
          that information for legitimate business or legal purposes.
        </TextSection>

        <TextSection>
          Where we can provide information access and correction, we will do so
          for free, except where it would require a disproportionate effort. We
          aim to maintain our services in a manner that protects information
          from accidental or malicious destruction. Because of this, after you
          delete information from our services, we may not immediately delete
          residual copies from our active servers and may not remove information
          from our backup systems. Please note that we may reject requests that
          are unreasonably repetitive, require disproportionate technical effort
          (for example, developing a new system or fundamentally changing an
          existing practice), risk the privacy of others, or would be extremely
          impractical (for instance, requests concerning information residing on
          backup tapes).
        </TextSection>

        <ItalicsHeader>Withdrawing consent</ItalicsHeader>
        <TextSection>
          You have the right to withdraw your consent to our processing of your
          information and your use of our Services. For example:
        </TextSection>
        <List>
          <ListItem>
            You can withdraw consent from email marketing or notifications by
            using the unsubscribe link in such communications.
          </ListItem>
          <ListItem>
            You can choose to withdraw your consent to our processing of your
            data and your use of the Services by closing your account, by using
            the "Delete account" option in your {APP_NAME} account settings.
          </ListItem>
        </List>

        <TextSection>
          Depending on your location, you can also email{" "}
          <a href={`mailto:${APP_SUPPORT_EMAIL}`}>{APP_SUPPORT_EMAIL}</a> to
          request that your personal information be deleted, excluding
          information we are legally required to retain.
        </TextSection>

        <Header>Security</Header>
        <TextSection>
          Your personal information is important to you, and protecting that
          information is important to us. We take reasonable measures to protect{" "}
          {APP_NAME} and our users from unauthorized access, alteration,
          disclosure, or loss of information we hold, including administrative,
          technical, and physical safeguards.
        </TextSection>

        <TextSection>
          For Protected Health Information (PHI), we implement security measures
          that meet or exceed HIPAA Security Rule requirements, including:
        </TextSection>
        <List>
          <ListItem>
            <strong>Encryption:</strong> All PHI is encrypted in transit and at
            rest using industry-standard protocols and algorithms.
          </ListItem>
          <ListItem>
            <strong>Access Controls:</strong> Role-based access controls, unique
            user authentication, automatic logout, and other technical
            safeguards.
          </ListItem>
          <ListItem>
            <strong>Intrusion Detection and Prevention:</strong> Real-time
            monitoring for unauthorized access attempts.
          </ListItem>
          <ListItem>
            <strong>Data Backups:</strong> Regular encrypted backups with secure
            storage and retention policies.
          </ListItem>
          <ListItem>
            <strong>Disaster Recovery:</strong> Comprehensive disaster recovery
            and business continuity plans.
          </ListItem>
          <ListItem>
            <strong>Physical Safeguards:</strong> Our data centers maintain
            robust physical security measures including access controls,
            surveillance, and environmental protections.
          </ListItem>
          <ListItem>
            <strong>Staff Training:</strong> Regular security and HIPAA
            compliance training for all staff with access to PHI.
          </ListItem>
        </List>

        <TextSection>
          Your account information is protected by a password. It is important
          that you protect against unauthorized access to your account and
          information by choosing your password carefully and by keeping your
          password and device secure, such as by logging out after using the
          Services.
        </TextSection>

        <TextSection>
          We follow generally accepted standards to protect the personal
          information submitted to us, both during transmission and after it is
          received. Here are some of the security measures we take:
        </TextSection>
        <List>
          <ListItem>
            We encrypt many of our services and certain information using secure
            socket layer technology (SSL).
          </ListItem>
          <ListItem>
            We review our information collection, storage and processing
            practices, including physical security measures, to guard against
            unauthorized access to systems.
          </ListItem>
          <ListItem>
            We restrict access to personal information to {APP_NAME} employees,
            contractors and agents who need to know that information in order to
            process it for us, and who are subject to strict contractual
            confidentiality obligations and may be disciplined or terminated if
            they fail to meet these obligations.
          </ListItem>
        </List>

        <TextSection>
          Despite our robust security measures, no method of transmission over
          the internet or method of electronic storage is 100% secure, and we
          cannot guarantee absolute security of the transmission or storage of
          your information. We promptly investigate and address any potential
          security incidents and will notify affected parties as required by
          applicable law.
        </TextSection>

        <Header>Changes to This Policy</Header>
        <TextSection>
          Our Privacy Policy may change from time to time, at any time. We will
          not reduce your rights under this Privacy Policy without your explicit
          consent. We will post any privacy policy changes on this page and, if
          the changes are significant, we will provide a more prominent notice
          (including, for certain services, email notification of privacy policy
          changes).
        </TextSection>

        <Header>Contact</Header>
        <TextSection>
          Please contact our Privacy Officer at{" "}
          <a href={`mailto:${APP_SUPPORT_EMAIL}`}>{APP_SUPPORT_EMAIL}</a> with
          any questions or concerns regarding this Privacy Policy or our HIPAA
          compliance practices.
        </TextSection>

        <TextSection>
          We regularly review our compliance with our Privacy Policy. When we
          receive formal written complaints, we will contact the person who made
          the complaint to follow up. We work with the appropriate regulatory
          authorities, including local data protection authorities and the U.S.
          Department of Health and Human Services, to resolve any complaints
          regarding the transfer or handling of personal data and PHI that we
          cannot resolve with our users directly.
        </TextSection>

        <Header>Authorized Uses of Your Information</Header>
        <TextSection>
          When healthcare providers use our platform, they should have obtained
          your authorization to process your PHI for the specific purpose of
          visualizing potential cosmetic procedure outcomes. This authorization
          typically includes:
        </TextSection>
        <List>
          <ListItem>
            Permission to capture or upload your images and relevant health
            information to our platform
          </ListItem>
          <ListItem>
            Consent for {APP_NAME} to process this information to create
            procedure visualizations
          </ListItem>
          <ListItem>
            Understanding that your information will be stored securely within
            our system in accordance with HIPAA requirements
          </ListItem>
          <ListItem>
            Acknowledgment that the visualizations are for informational
            purposes only and not guarantees of specific outcomes
          </ListItem>
        </List>
        <TextSection>
          At no point do we ask patients to waive their HIPAA rights in order to
          use our Services. Our platform is designed to operate within the
          framework of proper HIPAA-compliant authorizations while preserving
          all patient rights under applicable law.
        </TextSection>
      </TextContainer>
    </Container>
  );
}

const Container = styled.div`
  color: black;
  font-size: 13px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: left;
  width: 80%;
  margin: auto;
  overflow: hidden;
  margin-bottom: 40px;
  margin-top: 3rem;
`;

const TextContainer = styled.div`
  height: 100%;
  overflow: unset !important;
  padding-right: 5%;
  padding-bottom: 32px;
`;

const Header = styled.div`
  font-size: 13px;
  font-weight: bold;
  line-height: 20.8px;
  margin-top: 24px;
`;

const MainHeader = styled.div`
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 18px;
`;

const List = styled.ul`
  font-size: 13px;
  font-weight: 400;
  line-height: 20.8px;
  margin-top: 18px;
`;

const ListItem = styled.li`
  font-size: 13px;
  font-weight: 400;
  line-height: 20.8px;
  margin-top: 10px;
`;

const TextSection = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 20.8px;
  margin-top: 18px;
  text-transform: ${(props) => (props.$capitalize ? "uppercase" : "none")};
`;

const ItalicsHeader = styled.div`
  font-size: 13px;
  font-style: italic;
  line-height: 20.8px;
  margin-top: 24px;
`;
