"use client";
import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { device, useBreakpoints } from "../../lib/breakpoints";
import { Spacer } from "../../styles/CommonElements";
import { APP_NAME, MISSION_STATEMENT } from "../../helpers/constants";
import mixpanel from "mixpanel-browser";

export default function MissionStatement() {
  const { isMobile } = useBreakpoints();

  const handleMissionClick = () => {
    mixpanel.track("Mission Statement Click", {
      is_mobile: isMobile,
      section: "mission",
      action: "read_more",
    });
  };

  return (
    <Container>
      <BackgroundAccent />
      <ContentWrapper>
        <SectionTitle>Our Mission</SectionTitle>
        <Spacer $marginTop="1.5rem" />
        <MissionTextContainer>
          <MissionText>
            At <CompanyName>{APP_NAME}</CompanyName>, {MISSION_STATEMENT}
          </MissionText>
        </MissionTextContainer>
        <Spacer $marginTop="2rem" />
        <Divider />
        <Spacer $marginTop="2rem" />
        <FounderStorySummary>
          <HighlightText>
            Our founder struggled with his own journey
          </HighlightText>
        </FounderStorySummary>
        <Spacer $marginTop="1.5rem" />
        <CTAButton to="/mission" onClick={handleMissionClick}>
          <span>Discover the mission behind {APP_NAME}</span>
          <ButtonArrow>→</ButtonArrow>
        </CTAButton>
      </ContentWrapper>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  position: relative;
  padding: 6rem 0;
  overflow: hidden;
  background-color: var(--color-brand-complimentary-dark, #2a2a30);

  @media ${device.mobile} {
    padding: 5rem 0;
  }
`;

const BackgroundAccent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: var(--color-brand-primary);
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  text-align: center;
  position: relative;
  z-index: 2;
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  font-weight: 600;
  color: white;
  margin: 0;

  @media ${device.tablet} {
    font-size: 2.2rem;
  }

  @media ${device.mobile} {
    font-size: 2rem;
  }
`;

const MissionTextContainer = styled.div`
  position: relative;
  max-width: 900px;
  margin: 0 auto;
`;

const MissionText = styled.p`
  font-size: 1.5rem;
  line-height: 1.7;
  color: white;
  margin: 0;
  font-weight: 300;
  letter-spacing: 0.3px;

  @media ${device.tablet} {
    font-size: 1.3rem;
  }

  @media ${device.mobile} {
    font-size: 1.25rem;
    line-height: 1.7;
  }
`;

const FounderStorySummary = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.85);
  max-width: 800px;
  margin: 0 auto;
  font-weight: 300;

  @media ${device.tablet} {
    font-size: 1.1rem;
  }

  @media ${device.mobile} {
    font-size: 1rem;
    padding: 0 0.5rem;
  }
`;

const HighlightText = styled.span`
  color: var(--color-brand-complimentary);
  font-weight: 500;
  font-style: italic;
`;

const CompanyName = styled.span`
  font-weight: 700;
  color: var(--color-brand-primary);
`;

const Divider = styled.div`
  width: 80px;
  height: 3px;
  background-color: var(--color-brand-primary);
  margin: 0 auto;
`;

const CTAButton = styled(Link)`
  display: inline-flex;
  align-items: center;
  gap: 10px;
  background-color: var(--color-brand-primary);
  color: white;
  padding: 12px 24px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 1.1rem;
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
  }

  @media ${device.mobile} {
    font-size: 0.9rem;
    padding: 10px 20px;
  }
`;

const ButtonArrow = styled.span`
  font-size: 1.2rem;
  transition: transform 0.3s ease;

  ${CTAButton}:hover & {
    transform: translateX(4px);
  }
`;

const Tagline = styled.p`
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.7);
  margin: 1.5rem 0 0;
  font-weight: 300;
`;
