import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, NavLink, Link } from "react-router-dom";
import { showHeaderUi } from "../../helpers/general";
import { APP_LOGO_TEXT, APP_NAME } from "../../helpers/constants";
import { firebaseAuth } from "../../helpers/authentication";
import { BackLocationsContext } from "../../context/BackLocationsProvider";
import { device, useBreakpoints } from "../../lib/breakpoints";
import Button from "../Button";
import { AuthContext } from "../../context/AuthProvider";
import Hidden from "../Hidden";
import {
  DIALOG_NAME,
  useDialogSearchParam,
  useOpenParamDialog,
} from "../../helpers/useDialogSearchParam";
import { Dropdown } from "antd";
import { EditModal, SettingsModal } from "../profile";
import { ProText } from "../../styles/CommonElements";
import BookDemoButton from "./BookDemoButton";
import mixpanel from "mixpanel-browser";

const PROFILE_ICON = "/icons/profile.svg";
const MENU_ICON = "/icons/hamburger.svg";

export default function NavBar() {
  const location = useLocation();
  const openSignup = useOpenParamDialog(DIALOG_NAME.signup);
  const { user, userInfo, requireLogin } = useContext(AuthContext);
  const { clearBackLocations } = useContext(BackLocationsContext);
  const { isMobile, isTabletOrAbove, isDesktop } = useBreakpoints();

  const [isEditParamSet, removeEditParam] = useDialogSearchParam(
    DIALOG_NAME.edit
  );
  const [isSettingsParamSet, removeSettingsParam] = useDialogSearchParam(
    DIALOG_NAME.settings
  );

  const onLogout = async () => {
    try {
      await firebaseAuth.signOut();
      mixpanel.track("User Logged Out", {
        method: "email",
        last_page: location.pathname,
      });
    } catch (error) {
      console.error("Sign Out Error", error);
      mixpanel.track("Logout Error", {
        error_message: error.message,
      });
    }
  };

  const handleNavigation = (path) => {
    clearBackLocations();
    mixpanel.track("Navigation Click", {
      destination: path,
      from_page: location.pathname,
      is_mobile: isMobile,
      is_tablet: isTabletOrAbove,
    });
  };

  const handleLoginClick = () => {
    mixpanel.track("Login Button Click", {
      from_page: location.pathname,
      is_mobile: isMobile,
      is_tablet: isTabletOrAbove,
    });
    requireLogin();
  };

  const handleBookDemoClick = () => {
    mixpanel.track("Book Demo Click", {
      from_page: location.pathname,
      is_mobile: isMobile,
      is_tablet: isTabletOrAbove,
    });
  };

  const navItems = [
    {
      label: (
        <StyledLink to="/mission" onClick={clearBackLocations}>
          Our Mission
        </StyledLink>
      ),
      key: "mission",
    },
    {
      label: (
        <StyledLink to="/gallery" onClick={clearBackLocations}>
          Gallery
        </StyledLink>
      ),
      key: "gallery",
    },
    user && {
      label: (
        <StyledLink to="/use" onClick={clearBackLocations}>
          Use {APP_NAME}
        </StyledLink>
      ),
      key: "use",
    },
    user && {
      label: (
        <StyledLink to="/crm" onClick={clearBackLocations}>
          CRM
        </StyledLink>
      ),
      key: "crm",
    },
    {
      label: (
        <StyledLink to="/faq" onClick={clearBackLocations}>
          FAQ
        </StyledLink>
      ),
      key: "1",
    },
  ];

  const navItemsNotSignedIn = [
    {
      label: (
        <StyledLink
          onClick={() => {
            requireLogin();
          }}
        >
          Log in to <ProText>pro</ProText>
        </StyledLink>
      ),
      key: navItems.length,
    },
    {
      label: (
        <StyledLink to="/contact" onClick={clearBackLocations}>
          Book a demo
        </StyledLink>
      ),
      key: "2",
    },
    ...navItems,
  ];

  const userItems = [
    {
      label: (
        <StyledLink to={isMobile ? "/profile/edit" : `?${DIALOG_NAME.edit}=`}>
          Edit Profile
        </StyledLink>
      ),
      key: "4",
    },
    {
      label: (
        <StyledLink
          to={isMobile ? "/profile/edit" : `?${DIALOG_NAME.settings}=`}
        >
          Account Settings
        </StyledLink>
      ),
      key: "5",
    },
    {
      label: <StyledLink onClick={onLogout}>Log Out</StyledLink>,
      key: "6",
    },
  ];

  return (
    showHeaderUi(location) && (
      <Container>
        <LogoBarContainer>
          <Header>
            <Link to="/" onClick={() => handleNavigation("/")}>
              <Logo src={APP_LOGO_TEXT} alt="" />
            </Link>

            <InnerContainer>
              {isDesktop && (
                <>
                  <NavItem
                    to="/"
                    style={({ isActive }) => ({
                      fontWeight: isActive && 700,
                      color: isActive && "var(--color-highlight)",
                    })}
                    onClick={() => handleNavigation("/")}
                  >
                    Home
                  </NavItem>
                  <NavItem
                    to="/mission"
                    style={({ isActive }) => ({
                      fontWeight: isActive && 700,
                      color: isActive && "var(--color-highlight)",
                    })}
                    onClick={() => handleNavigation("/mission")}
                  >
                    Our Mission
                  </NavItem>

                  <NavItem
                    to="/gallery"
                    style={({ isActive }) => ({
                      fontWeight: isActive && 700,
                      color: isActive && "var(--color-highlight)",
                    })}
                    onClick={() => handleNavigation("/gallery")}
                  >
                    Gallery
                  </NavItem>

                  {user && (
                    <NavItem
                      to="/use"
                      style={({ isActive }) => ({
                        fontWeight: isActive && 700,
                        color: isActive && "var(--color-highlight)",
                      })}
                      onClick={() => handleNavigation("/use")}
                    >
                      Use {APP_NAME}
                    </NavItem>
                  )}

                  {user && userInfo?.hasCrmAccess && (
                    <NavItem
                      to="/crm"
                      style={({ isActive }) => ({
                        fontWeight: isActive && 700,
                        color: isActive && "var(--color-highlight)",
                      })}
                      onClick={() => handleNavigation("/crm")}
                    >
                      CRM
                    </NavItem>
                  )}

                  <NavItem
                    to="/faq"
                    style={({ isActive }) => ({
                      fontWeight: isActive && 700,
                      color: isActive && "var(--color-highlight)",
                    })}
                    onClick={() => handleNavigation("/faq")}
                  >
                    FAQ
                  </NavItem>

                  {/*
                  <NavItem
                    to="/contact"
                    style={({ isActive }) => ({
                      fontWeight: isActive && 700,
                      color: isActive && "var(--color-highlight)",
                    })}
                    onClick={clearBackLocations}
                  >
                    {isTabletOrAbove ? "Contact Us" : "Contact"}
                  </NavItem>
                  */}
                </>
              )}

              {!user && (
                <>
                  <Hidden mobile>
                    <BookDemoButton onClick={handleBookDemoClick} />
                  </Hidden>
                  {isDesktop && (
                    <StyledButton
                      variant="text"
                      width={"150px"}
                      onClick={handleLoginClick}
                      style={{ marginLeft: "-18px" }}
                    >
                      Log in to <ProText>pro</ProText>
                    </StyledButton>
                  )}
                </>
              )}

              {!isDesktop && (
                <Dropdown
                  menu={{
                    items: user ? navItems : navItemsNotSignedIn,
                  }}
                  trigger={["click"]}
                  dropdownRender={(menu) => {
                    return <StyledDropdownMenu>{menu}</StyledDropdownMenu>;
                  }}
                >
                  <NavButton>
                    <MenuIcon src={MENU_ICON} alt="Menu" />
                  </NavButton>
                </Dropdown>
              )}

              {user && (
                <Dropdown
                  menu={{
                    items: userItems,
                  }}
                  trigger={["click"]}
                  dropdownRender={(menu) => {
                    return <StyledDropdownMenu>{menu}</StyledDropdownMenu>;
                  }}
                >
                  <ProfileButton
                    variant="white"
                    rounded="round"
                    style={({ isActive }) => ({
                      border: isActive && "2px solid var(--color-gray-100)",
                      borderRadius: isActive && "50%",
                    })}
                    onClick={(e) => e.preventDefault()}
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <ProfilePic src={userInfo?.pfp ?? PROFILE_ICON} alt="" />
                    </a>
                  </ProfileButton>
                </Dropdown>
              )}
            </InnerContainer>
          </Header>
        </LogoBarContainer>

        <EditModal
          isOpen={isEditParamSet && !isMobile}
          onClose={() => removeEditParam()}
        />

        <SettingsModal
          isOpen={isSettingsParamSet && !isMobile}
          onClose={() => removeSettingsParam()}
        />
      </Container>
    )
  );
}

const Container = styled.div`
  z-index: 100;
  width: 100%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
`;

const LogoBarContainer = styled.div`
  z-index: 1;
  width: 100%;
  height: 80px;
  position: relative;
  background: var(--color-primary);
`;

const Header = styled.div`
  display: flex;
  background-color: ${(props) => props.$isOnEdit && "var(--color-primary)"};
  padding-left: 22px;
  padding-right: 22px;
  height: 80px;
  padding-top: 8px;
  align-items: center;
  justify-content: space-between;

  @media (${device.tablet}) {
    justify-content: end;
    height: 80px;
    gap: 2rem;
    padding-right: 2.5rem;
  }

  @media (${device.desktop}) {
    justify-content: end;
    height: 80px;
    gap: 2rem;
    padding-right: 2.5rem;
  }
`;

const InnerContainer = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 32px;
`;

const Logo = styled.img`
  top: 28px;
  left: 24px;
  display: block;
  width: auto;
  height: 26px;

  @media ${device.desktop}, ${device.tablet} {
    position: absolute;
    top: 31px;
    left: 22px;
  }
`;

const NavItem = styled(NavLink)`
  font-weight: 500;
  color: var(--color-gray-100);
  font-family: var(--font-primary);
  font-size: 16px;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: fit-content;
`;

const ProfileButton = styled(NavLink)`
  height: 44px;
  width: 44px;

  img {
    height: 100%;
    width: 100%;
    background: white;
    border-radius: 50%;
    padding: 0px;
  }
`;

const ProfilePic = styled.img`
  object-fit: cover;
  object-position: center;
`;

const StyledButton = styled(Button)`
  font-size: 15px;
  width: fit-content;
  font-family: var(--font-primary);
  font-weight: 600;
`;

const StyledDropdownMenu = styled.div`
  max-height: 400px;
  overflow: auto;
  border-radius: 8px;
  box-shadow: 0px 4px 12.4px 0px rgba(21, 21, 21, 0.74);

  .ant-dropdown-menu {
    border: 0 !important;
    background: white !important;

    a {
      color: black !important;
    }

    li:hover {
      background-color: var(--color-gray-50) !important;
    }

    .ant-dropdown-menu-item-divider {
      background-color: rgba(0, 0, 0, 0.1) !important;
    }
  }
`;

const StyledLink = styled(Link)`
  @media ${device.tablet}, ${device.desktop} {
    width: 100%;
  }
`;

const NavButton = styled(Button)`
  font-size: 15px;
  width: fit-content;
  background: var(--color-primary);
  color: var(--color-gray-100);
  border: none;
  cursor: pointer;
`;

const MenuIcon = styled.img`
  width: 24px;
  height: 24px;
`;
