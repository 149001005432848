import React from "react";
import { Footer as _Footer } from "antd/es/layout/layout";
import { APP_LOGO_TEXT, FULL_COMPANY_NAME } from "../helpers/constants";
import { showFooterUi } from "../helpers/general";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { device } from "../lib/breakpoints";
import { StyledLink } from "../styles/CommonElements";

export default function NavFooter() {
  const location = useLocation();

  return (
    showFooterUi(location) && (
      <Footer>
        <FooterContent>
          <SocialContainer>
            <StyledLink to="/" rel="noreferrer">
              <Logo src={APP_LOGO_TEXT} alt="Company Logo" />
            </StyledLink>
          </SocialContainer>

          <OtherContainer>
            <FooterLinks>
              <FooterLink
                to="/contact"
                className={
                  location.pathname.includes("/contact") ? "selected" : ""
                }
              >
                Contact
              </FooterLink>
              <Separator>|</Separator>
              <FooterLink
                to="/terms-of-use"
                className={
                  location.pathname.includes("/terms-of-use") ? "selected" : ""
                }
              >
                Terms
              </FooterLink>
              <Separator>|</Separator>
              <FooterLink
                to="/privacy"
                className={
                  location.pathname.includes("/privacy") ? "selected" : ""
                }
              >
                Privacy
              </FooterLink>
              {/*
              <Separator>|</Separator>
              <FooterLink
                to="/guidelines"
                className={
                  location.pathname.includes("/guidelines") ? "selected" : ""
                }
              >
                Guidelines
              </FooterLink>
              */}
            </FooterLinks>
            <CopyrightText>
              © {new Date().getFullYear()} {FULL_COMPANY_NAME}
            </CopyrightText>
          </OtherContainer>
        </FooterContent>
      </Footer>
    )
  );
}

const Footer = styled(_Footer)`
  background: #f3f1ed;
  padding: 2rem 1rem 1.5rem;
  z-index: 2;
  width: 100%;

  @media ${device.tablet} {
    padding: 2rem 2rem 1.5rem;
  }

  @media ${device.desktop} {
    padding: 2rem 2rem 1.5rem;
  }
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`;

const CopyrightText = styled.div`
  font-size: 11px;
  font-weight: 500;
  color: #757575;
  font-style: normal;
  line-height: 1.5;
  margin-top: 0.5rem;
  transition: color 0.2s ease;
`;

const FooterLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
`;

const FooterLink = styled(Link)`
  font-size: 12px;
  color: #757575;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.2s ease;
  padding: 5px 3px;

  &:hover {
    color: var(--color-highlight);
    transform: translateY(-1px);
  }

  &.selected {
    color: var(--color-highlight);
    font-weight: 700;
  }

  @media ${device.tablet} {
    font-size: 13px;
  }
`;

const Separator = styled.span`
  color: #bebebe;
  font-size: 12px;
  margin: 0 2px;

  @media ${device.tablet} {
    margin: 0 5px;
    font-size: 13px;
  }
`;

const Logo = styled.img`
  height: 32px;
  width: auto;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }

  @media ${device.tablet} {
    height: 36px;
  }
`;

const OtherContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const SocialContainer = styled.div`
  text-align: center;

  @media ${device.tablet} {
    margin-bottom: 0rem;
  }

  @media ${device.desktop} {
    margin-bottom: 0rem;
  }
`;
