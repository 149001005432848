"use client";
import React from "react";
import styled from "styled-components";
import { device, useBreakpoints } from "../../lib/breakpoints";
import { Spacer, Subtitle } from "../../styles/CommonElements";
import BookDemoButton from "../../components/general/BookDemoButton";
import { APP_NAME } from "../../helpers/constants";
import mixpanel from "mixpanel-browser";

const BANNER_IMAGE_MOBILE = "/images/home/man-008.svg";
const BANNER_IMAGE_DESKTOP = "/images/home/man-008.svg";

const HomeMain = () => {
  const { isMobile } = useBreakpoints();

  const handleBookDemoClick = () => {
    mixpanel.track("Hero Book Demo Click", {
      is_mobile: isMobile,
      section: "hero",
    });
  };

  return (
    <Container>
      <InfoOverlay>
        <Title>Cosmetic predictions to drive bookings</Title>
        <Spacer $marginTop="1rem" />
        <StyledSubtitle>
          <b>{APP_NAME}</b> is your partner for superior customer experiences
          online & in-office
        </StyledSubtitle>
        <Spacer $marginTop="1.5rem" />
        <BookDemoButton onClick={handleBookDemoClick} />
      </InfoOverlay>
      <Cover />
      <BannerImage $isMobile={isMobile} />
    </Container>
  );
};

export default HomeMain;

const Container = styled.div`
  width: 100%;
  height: 770px;
  position: relative;
`;

const BannerImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${(props) =>
    props.$isMobile
      ? `url(${BANNER_IMAGE_MOBILE})`
      : `url(${BANNER_IMAGE_DESKTOP})`};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: absolute;

  @media ${device.mobile} {
    background-position: top center;
  }
`;

const Cover = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 1;
`;

const Logo = styled.img`
  width: auto;
  height: 60px;

  @media ${device.mobile} {
    text-align: center;
    margin: auto;
    justify-content: center;
  }
`;

const InfoOverlay = styled.div`
  position: absolute;
  left: 14%;
  top: 11%;
  width: 750px;
  line-height: 36px;
  text-align: left;
  z-index: 2;
  color: white;
  // text-shadow: rgba(255, 255, 255, 0.5) 1px 0 4px;

  @media ${device.tablet} {
    scale: 0.95;
    width: 550px;
    top: 7%;
  }

  @media ${device.mobile} {
    scale: 1;
    width: 90%;
    top: 5%;
    left: 5%;
    line-height: 32px;
  }
`;

const StyledSubtitle = styled(Subtitle)`
  @media ${device.tablet}, ${device.mobile} {
    width: 300px;
    line-height: 24px;
  }

  @media ${device.mobile} {
    width: 100%;
    font-size: 1.25rem;
    line-height: 1.7;
  }
`;

const Title = styled.h1`
  font-family: "widescreen", Sans-serif;
  font-size: clamp(2rem, 3.75vw, 3.5rem);
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 0;

  @media ${device.tablet} {
    font-size: 3rem;
  }

  @media ${device.mobile} {
    font-size: 2.25rem;
    line-height: 1.2;
  }
`;
