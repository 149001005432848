/* eslint-disable no-useless-escape */
import { getAuth } from "firebase/auth";
import reportModel from "../lib/firebase/reportModel";
import { noHeaderUiPaths, noFooterUiPaths, storyworldTypes } from "./constants";
import { employeeIds } from "./employees";
import {
  getStoryworldPolls,
  getStoryworldQuests,
  getQuestSubmissions,
  getVoteCountForSubmission,
  getUserTrackingData,
} from "./firebase";
import { greenlistWords } from "./greenlist";
import { profaneWords } from "./profanity";

const Filter = require("bad-words"),
  filter = new Filter();

const list = require("badwords-list");
list.array = list.array.concat(profaneWords);

export function sleep(seconds) {
  return new Promise((resolve) => {
    setTimeout(resolve, 1000 * seconds);
  });
}

export function addNewMessage(messages, newMessage) {
  const newMessages = [...messages];
  newMessages.push(newMessage);
  return newMessages;
}

export function containsProfanity(text) {
  text = text.toLowerCase();
  greenlistWords.forEach((word) => {
    text = text.replace(word, "");
  });
  const flaggedWords = [];
  list.array.forEach((word) => {
    if (text.includes(word.toLocaleLowerCase())) flaggedWords.push(word);
  });
  for (let i = 0; i < flaggedWords.length; i++) {
    if (!greenlistWords.includes(flaggedWords[i].toLocaleLowerCase()))
      return true;
  }
  return false;
}

export function removeProfanity(text) {
  return filter.clean(text);
}

export function saveProfanityList() {
  let fileData = JSON.stringify(list.array);
  fileData = fileData
    .replace("[", "")
    .replaceAll('"', "")
    .replaceAll(",", "\n")
    .replace("]", "");
  const blob = new Blob([fileData], { type: "text/plain" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.download = "profanity-text-list.txt";
  link.href = url;
  link.click();
}

export function shuffleArray(array) {
  let currentIndex = array.length,
    randomIndex;
  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }
  return array;
}

export function removeItemOnce(arr, value) {
  var index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}

export async function getQuestsWithPolls(id) {
  const storyworldQuestsPromise = await getStoryworldQuests(id);
  const storyworldPollsPromise = await getStoryworldPolls(id);
  storyworldQuestsPromise.forEach((el) => {
    const result = storyworldPollsPromise.filter(function (e) {
      return e.questId === el.id;
    });
    if (result.length > 0) {
      el.poll = result[0];
    }
  });
  return storyworldQuestsPromise;
}

export function isTimePassed(time) {
  if (new Date() > time) return true;
  else return false;
}

export function isQuestCompleted(quest) {
  if (!quest || quest.title === "") return false;
  if (isTimePassed(quest.endTimestamp.toDate())) {
    return true;
  } else {
    return false;
  }
}

export function isQuestOpen(quest) {
  if (!quest || quest.startTimestamp === undefined) return false;
  if (
    isTimePassed(quest.startTimestamp.toDate()) &&
    !isTimePassed(quest.endTimestamp.toDate()) &&
    !quest.isDraft
  ) {
    return true;
  } else {
    return false;
  }
}

export function isPollCompleted(poll) {
  if (!poll || !poll.endTimestamp) return false;
  if (isTimePassed(poll.endTimestamp.toDate())) {
    return true;
  } else {
    return false;
  }
}

export function isPollOpen(poll) {
  if (!poll || poll.isDraft === undefined) return false;
  if (
    isTimePassed(poll.startTimestamp.toDate()) &&
    !isTimePassed(poll.endTimestamp.toDate()) &&
    !poll.isDraft
  ) {
    return true;
  } else {
    return false;
  }
}

export function isBeforePoll(poll) {
  if (!poll) return false;
  if (!isTimePassed(poll.startTimestamp.toDate()) && !poll.isDraft) {
    return true;
  } else {
    return false;
  }
}

export function isBeforeQuest(quest) {
  if (!quest) return false;
  if (!isTimePassed(quest.startTimestamp.toDate()) && !quest.isDraft) {
    return true;
  } else {
    return false;
  }
}

export function isQuestOrPollOpen(quest) {
  if (isQuestOpen(quest)) {
    return true;
  } else if (isPollOpen(quest.poll)) {
    return true;
  }
  return false;
}

export function downloadImage(src) {
  const img = new Image();
  img.crossOrigin = "anonymous"; // This tells the browser to request cross-origin access when trying to download the image data.
  img.src = src;
  img.onload = () => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0);
    const a = document.createElement("a");
    a.download = "download.png";
    a.href = canvas.toDataURL("image/png");
    a.click();
  };
}

export async function downloadDataFile() {
  const data = await getUserTrackingData();
  await sleep(1);
  const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
    JSON.stringify(data)
  )}`;
  const link = document.createElement("a");
  link.href = jsonString;
  link.download = `Data Export (${
    process.env.REACT_APP_DEPLOYMENT
  }) - ${new Date()}.json`;
  link.click();
}

export async function getFileFromUrl(url) {
  try {
    const response = await fetch(url);
    if (response.status !== 200) {
      throw new Error(
        `Unable to download file. HTTP status: ${response.status}`
      );
    }
    const blob = await response.blob();
    if (blob.type === "text/html") {
      throw new Error("File is not an image");
    }
    return blob;
  } catch (error) {
    console.error("Error downloading the file:", error.message);
    return false;
  }
}

export function getQuestAndPollStates(quest) {
  return {
    quest: {
      isCompleted: isQuestCompleted(quest),
      isOpen: isQuestOpen(quest),
    },
    poll: quest.poll
      ? {
          isCompleted: isPollCompleted(quest.poll),
          isOpen: isPollOpen(quest.poll),
          confirmed: Boolean(quest.poll.confirmed),
        }
      : null,
  };
}

const amountOfWinners = 3;

export function getWinningSubmissionsFromList(submissions) {
  submissions = submissions.filter(function (e) {
    return e.rejected !== true;
  });
  submissions.sort(function (a, b) {
    return parseFloat(b.voteCount) - parseFloat(a.voteCount);
  });
  const tiedResults = [];
  const winningResults = [];
  var i = 0;
  for (const sub of submissions) {
    if (i === 0) {
      tiedResults.push(sub);
    } else if (sub.voteCount === tiedResults[0].voteCount) {
      tiedResults.push(sub);
    } else {
      winningResults.push(sub);
    }
    i += 1;
  }
  tiedResults.sort(function (a, b) {
    return a.createdAt.toDate() - b.createdAt.toDate();
  });
  var finalResults = tiedResults.concat(winningResults);
  if (finalResults.length >= amountOfWinners) {
    finalResults = finalResults.splice(0, finalResults.length);
  } else {
    finalResults = finalResults.splice(0, finalResults.length);
  }

  const finalResultsNoEmployees = finalResults.filter(
    (res) => !employeeIds.includes(res.creator)
  );
  const finalResultsEmployees = finalResults.filter((res) =>
    employeeIds.includes(res.creator)
  );

  return finalResultsNoEmployees.concat(finalResultsEmployees);
}

export function isOnMobile() {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}

export function showHeaderUi(currentLocation) {
  for (let i = 0; i < noHeaderUiPaths.length; i++) {
    if (currentLocation.pathname.includes(noHeaderUiPaths[i])) return false;
  }
  return true;
}

export function showFooterUi(currentLocation) {
  for (let i = 0; i < noFooterUiPaths.length; i++) {
    if (currentLocation.pathname.includes(noFooterUiPaths[i])) return false;
  }
  return true;
}

export function reduceArrayToRows(rows, key, index) {
  return (
    (index % 2 === 0 ? rows.push([key]) : rows[rows.length - 1].push(key)) &&
    rows
  );
}

export function reduceArrayToFourRows(rows, key, index) {
  return (
    (index % 4 === 0 ? rows.push([key]) : rows[rows.length - 1].push(key)) &&
    rows
  );
}

export async function getQuestSubmissionsAndVotes(questInfo) {
  const userQuestsPromise = await getQuestSubmissions(questInfo);
  const results = [];
  await Promise.all(
    userQuestsPromise.map(async (submission) => {
      const voteCount = await getVoteCountForSubmission(submission);
      submission.voteCount = voteCount !== undefined ? voteCount : 0;
      results.push(submission);
    })
  );
  return results;
}

export function getAdjustedEpisodeText({
  number,
  showEpText = true,
  showShortenedEpText = false,
}) {
  let result;
  if (number === 1 && showEpText) result = `Episode ${number}`;
  else if (number === 1 && !showEpText) result = `${number}`;
  else if (showShortenedEpText) {
    result = `#${number}`;
  } else if (showEpText) {
    result = `Episode ${number}`;
  } else {
    result = number;
  }
  return result;
}

export function removeTags(str) {
  if (str === null || str === "") return false;
  else str = str.toString();
  return str.replace(/(<([^>]+)>)/gi, "");
}

export function hasImgTag(str) {
  if (str === null || str === "") return false;
  else str = str.toString();
  const matches = str.match("img");
  return matches && matches.length > 0;
}

export function blobToBase64(blob) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export function timeout({ asyncFn, pollInterval, maxWaitTime, validateFn }) {
  maxWaitTime *= 1000; // convert to ms
  const startTime = Date.now();

  // TODO: find alternative to async/await
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    while (Date.now() - startTime < maxWaitTime) {
      try {
        const result = await asyncFn();
        const isValid = validateFn
          ? validateFn(result)
          : result && result.length > 0;
        if (isValid) return resolve(result);
        await sleep(pollInterval);
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }
    return reject(new Error(`Operation timed out after ${maxWaitTime}ms`));
  });
}

export function removeIllegalCharacters(input) {
  return input.replace(/[^a-zA-Z0-9]/g, "");
}

export function isOniOS() {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  return /iPhone|iPad|iPod/.test(window.navigator.userAgent) && isSafari;
}

export function removeRepliesWithNoRoot(comments) {
  let newComments = [];
  comments.forEach((c) => {
    if (c.replyTo) {
      comments.forEach((c2) => {
        if (c.replyTo === c2.id) {
          newComments.push(c);
        }
      });
    } else {
      newComments.push(c);
    }
  });
  return newComments;
}

export function containsEmptyTags(content) {
  content = content = content.replace(/(<\/?[^>]+(>|$)|&nbsp;|\s)/g, "");
  if (content === "") return true;
  else return false;
}

export function isArtQuest(quest) {
  if (!quest || !quest.submissionRequirements) return false;
  return quest.submissionRequirements.includes("image");
}

export function getTimeFromDate(futureDate) {
  const firstDate = futureDate;
  const secondDate = new Date();
  if (firstDate < secondDate) return false;

  const milliDiff = firstDate.getTime() - secondDate.getTime();
  const totalSeconds = Math.floor(milliDiff / 1000);
  const totalMinutes = Math.floor(totalSeconds / 60);
  const totalHours = Math.floor(totalMinutes / 60);

  const remMinutes = totalMinutes % 60;

  return `${totalHours}h ${remMinutes}m`;
}

export function isCatalogComic(comicType) {
  if (
    comicType === storyworldTypes.ARCHIVED ||
    comicType === storyworldTypes.STORYWORLD
  ) {
    return false;
  }

  return true;
}

export function randomIntFromInterval(min, max) {
  // min included
  return Math.floor(Math.random() * (max - min) + min);
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function readAsDataURL(file) {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onerror = reject;
    fr.onload = () => {
      resolve(fr.result);
    };
    fr.readAsDataURL(file);
  });
}
