"use client";
// ----- Components Import -----
import { styled } from "styled-components";
import { Col, Row } from "antd";
import { APP_NAME } from "../../helpers/constants";
import { device, useBreakpoints } from "../../lib/breakpoints";
import BookDemoButton from "../../components/general/BookDemoButton";
import { Spacer } from "../../styles/CommonElements";
import mixpanel from "mixpanel-browser";

const bannerImage = "/images/home/man-005.svg";
const description = `We are ${APP_NAME}. We bring cosmetic surgery possibilities to life every day. Our state-of-the-art procedure prediction platform makes it easy for your customers to engage with your surgery services at their own home and in your office, with an unrivaled level of realism.`;

export default function HomeBanner() {
  const { isTabletOrAbove } = useBreakpoints();

  const handleBookDemoClick = () => {
    mixpanel.track("Banner Book Demo Click", {
      is_mobile: !isTabletOrAbove,
      section: "banner",
      description_length: description.length,
    });
  };

  return (
    <Container>
      {isTabletOrAbove ? (
        <StyledRow>
          <StyledCol span={10}>
            <Title>
              A{" "}
              <Highlight $color="var(--color-brand-primary)">
                simpler, smarter
              </Highlight>{" "}
              way to grow your business.
            </Title>
            <Subtitle>{description}</Subtitle>
            <BookDemoButton onClick={handleBookDemoClick} />
          </StyledCol>
          <Col span={14}>
            <Banner />
          </Col>
        </StyledRow>
      ) : (
        <>
          <StyledRow>
            <StyledCol span={24}>
              <Title>
                A{" "}
                <Highlight $color="var(--color-brand-primary)">
                  simpler, smarter
                </Highlight>{" "}
                way to grow your business.
              </Title>
              <Subtitle>{description}</Subtitle>
            </StyledCol>
          </StyledRow>
          <Spacer $marginTop="2rem" />
          <Banner />
        </>
      )}
    </Container>
  );
}

// ----- Styles -----
const Container = styled.div`
  width: 100%;
  height: fit-content;
  max-height: 700px;
  padding: 0 8%;

  @media ${device.mobile} {
    overflow: hidden; /* Ensure no content overflows */
    max-height: none; /* Remove max-height constraint on mobile */
  }
`;

const Banner = styled.div`
  width: 100%;
  height: 100%;
  min-height: 350px;
  height: 450px;
  background-image: url(${bannerImage});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  padding: 0 5%;
  overflow: hidden; /* Prevent image from overflowing */

  @media ${device.mobile} {
    min-height: 250px; /* Reduced height for mobile */
    height: 300px; /* Fixed height for mobile */
    background-position: center; /* Center the image */
    background-size: contain; /* Ensure image is fully contained */
    margin: 0 -8%; /* Compensate for parent padding */
    padding: 0; /* Remove padding */
    width: calc(100% + 16%); /* Adjust width to account for negative margins */
  }
`;

const Highlight = styled.span`
  color: ${(props) => props.$color || "inherit"};
`;

const StyledCol = styled(Col)`
  padding: 0rem 3rem;
  display: inline-flex;
  gap: 18px;
  justify-content: top;
  flex-direction: column;

  @media ${device.mobile} {
    padding: 0;
  }
`;

const StyledRow = styled(Row)`
  height: 100%;
  text-align: left;
`;

const Subtitle = styled.div`
  font-size: 16px;

  @media ${device.tablet} {
    font-size: 14px;
  }

  @media ${device.mobile} {
    font-size: 1.15rem;
    line-height: 1.7;
    margin-bottom: 1rem;
  }
`;

const Title = styled.h2`
  font-size: 48px;
  font-weight: 600;
  color: var(--color-brand-complimentary);
  line-height: 1.25;

  @media ${device.tablet} {
    font-size: 42px;
  }

  @media ${device.mobile} {
    font-size: 2.5rem;
    line-height: 1.3;
    margin-bottom: 1rem;
  }
`;
