"use client";
// ----- Components Import -----
import React from "react";
import styled from "styled-components";
import BookDemoButton from "../../components/general/BookDemoButton";
import StyledSlider from "../../components/general/StyledSlider"; // Import the StyledSlider component

// ----- Assets Import -----
import { APP_NAME } from "../../helpers/constants";
import { device, useBreakpoints } from "../../lib/breakpoints";
import { Spacer, Subtitle } from "../../styles/CommonElements";
import features from "../../helpers/features";
import Card from "../../components/general/Card";

export default function HomeCarousel() {
  const { isTabletOrAbove } = useBreakpoints();

  const settings = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 5000,
    arrows: false, // Disable arrows
    dots: true, // Enable dots
    centerMode: true, // Enable center mode
    centerPadding: "0px", // Remove center padding
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Container>
      <Title>
        Don't just sell.{" "}
        <Highlight $color="var(--color-brand-primary)">Inspire.</Highlight>
      </Title>
      <Spacer $marginTop="1rem" />
      <Subtitle>
        Bring your customers closer to the future of cosmetic surgery procedures
        with our state-of-the-art prediction platform.
      </Subtitle>
      <Spacer $marginTop="5rem" />

      <StyledSlider settings={settings}>
        {features.map((feature, index) => (
          <Card key={index} feature={feature} />
        ))}
      </StyledSlider>

      <Spacer $marginTop="5rem" />

      <CTASection>
        <CTAText>See what {APP_NAME} can do for your business.</CTAText>
        <Spacer $marginTop="2.5rem" />
        <BookDemoButton />
      </CTASection>
    </Container>
  );
}

// ----- Styles -----
const Container = styled.div`
  width: 100%;
  height: fit-content;
  padding: 100px 20px;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.05);
`;

const Highlight = styled.span`
  color: ${(props) => props.$color || "inherit"};
`;

const Title = styled.h2`
  font-size: 92px;
  font-weight: 600;
  color: var(--color-brand-complimentary);
  line-height: 1.25;

  @media ${device.tablet} {
    font-size: 74px;
  }

  @media ${device.mobile} {
    font-size: 56px;
  }
`;

const CTASection = styled.div`
  margin-top: 3rem;
  text-align: center;
`;

const CTAText = styled.h3`
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 1rem;
  color: black;

  @media ${device.tablet} {
    font-size: 20px;
  }

  @media ${device.mobile} {
    font-size: 18px;
  }
`;
